
import { useRouter } from "vue-router";
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import Header from "@/components/Header.vue";
import SettingVue from "@/views/home/components/Setting.vue";
import MessageClient from "@/services/message/MessageClient";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "Mine",
  components: {
    Header,
  },
  data() {
    const router = useRouter();
    return {
      router: router,
      login: false,
    };
  },
  created() {
    this.login = SystemUtils.loginUser.id ? true : false;
  },
  methods: {
    openDetail(name: string) {
      switch (name) {
        case "Language":
          this.router.push({
            path: "/MineLanguage",
          });
          break;

        case "Schedule":
          this.router.push({
            path: "/ScheculeSetting",
          });
          break;
        case "About":
          this.router.push({
            path: "/About",
          });
          break;
        case "cache":
          this.router.push({
            path: "/CachePage",
          });
          break;
        case "updatePwd":
          this.router.push({
            path: "/UpdatePwd",
          });
          break;
        case "messageSetting":
          this.router.push({
            path: "/MessageSetting",
          });
          break;
        case "logOut":
          this.router.push({
            path: "/login",
          });
          MessageClient.mqttClose();
          break;
        case "printPage":
          this.router.push({
            path: "/printPage",
          });
          MessageClient.mqttClose();
          break;
        default:
          break;
      }
    },

    async openModel() {
      const modal = await modalController.create({
        component: SettingVue,
        cssClass: "notice-modal-class",
        componentProps: {
          data: {
            content: "content",
          },
        },
      });
      await modal.present();
    },
  },
});
